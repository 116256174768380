import { graphql, Script } from "gatsby"
import React from "react"
import ContactSales from "../../components/common/ContactSales"
import MainLayout from "../../layouts/MainLayout"
import Banner from "../../components/team-ai/Banner"
import Technologiesweuse from "../../components/team-ai/Technologiesweuse"
import Introsection from "../../components/team-ai/Introsection"
import KeyFeatures from "../../components/team-ai/KeyFeatures"
import KeyChallengesSolution from "../../components/team-ai/KeyChalanges"
import Result from "../../components/invocom/Result"
import Conclusion from "../../components/team-ai/Conclusion"
import CenterImage2 from "../../components/team-ai/CenterImage"
import CenterImage from "../../components/team-ai/CenterImage2"
import SEORevamp from "../../components/common/SEO_Revamp"

export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })
  console.log("All GraphQL Data:", data)

  // Log specific sections
  console.log("Workflow Data:", data?.allStrapiAiHologram?.nodes[0]?.workflow)
  console.log(
    "Workflow Steps:",
    data?.allStrapiAiHologram?.nodes[0]?.workflow?.workflow_steps?.data
  )
  return (
    <>
      <Script
        src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
        strategy="post-hydrate"
      />

      <SEORevamp
        title={metaTitle}
        description={metaDescription}
        schemas={headSchema}
        image="https://invozone-backend.s3.amazonaws.com/hire_elixir_developers_f701d7b38c.webp"
      />
    </>
  )
}

const teamAi = ({ data }) => {
  console.log(data)

  const heroSection = data?.strapiPage?.sections[0]
  const technology = data?.strapiPage?.sections[1]
  const intro = data?.strapiPage?.sections[2]
  const key = data?.strapiPage?.sections[3]
  const critical = data?.strapiPage?.sections[4]
  const result = data?.strapiPage?.sections[5]
  const conclusion = data?.strapiPage?.sections[6]

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <MainLayout schemas={bodySchema}>
      <Banner
        strapiData={heroSection}
        hatch={true}
        breadCrumb={data?.strapiPage?.buttons}
      />
      <Technologiesweuse strapiData={technology} hideCurve={true} />
      <Introsection strapiData={intro} hatch={true} ocr={true} />
      <KeyFeatures columnSizeHatch={4} strapiData={key} ocr={true} weapon={true} />
      <KeyChallengesSolution strapiData={critical} weapon={true} />
      <CenterImage/>
      <Result strapiData={result} columnSize={4} hatch={true} />
      <CenterImage2/>
      <Conclusion strapiData={conclusion} />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query teamAi {
    strapiPage(slug: { eq: "team-ai" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          title2
          subTitle
          jsonData {
            caseServices {
              teamAi_caseStudy {
                skills
              }
            }
          }
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
  }
`

export default teamAi
