import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import * as styles from "./KeyFeatures.module.scss"

function PerfectEngagement({
  strapiData,
  predicto,
  easyfill,
  ocr,
  weapon,
  columnSizeHatch,
}) {
  return (
    <>
      <div className={`${styles.bets} ${ocr ? styles.ocrBets : ""}`}>
        <Container>
          <h2
            className={`${styles.heading} ${
              weapon ? styles.weaponHeading : ""
            }`}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <p
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <div>
            {/* First Row - 4 cards */}
            <Row className="justify-content-center">
              {strapiData?.cards?.slice(0, 4).map((e, i) => (
                <Col
                  key={i}
                  lg={3}
                  md={6}
                  sm={12}
                  className={`${styles.colPad} d-flex gap-30`}
                >
                  <div
                    className={`${styles.card} ${ocr ? styles.ocrCard : ""}`}
                  >
                    <img
                      src={e?.image1?.[0]?.localFile?.publicURL}
                      alt="tick"
                    />
                    <h3
                      dangerouslySetInnerHTML={{
                        __html: e?.title,
                      }}
                      className={`${styles.cardHead} ${
                        ocr ? styles.ocrCardHead : ""
                      }`}
                    ></h3>
                    <p
                      className={`${styles.mainDes} ${
                        ocr ? styles.ocrmainDec : ""
                      }`}
                      dangerouslySetInnerHTML={{
                        __html: e?.subTitle,
                      }}
                    />
                  </div>
                </Col>
              ))}
            </Row>

            {/* Second Row - remaining cards centered */}
            <Row className="justify-content-center">
              {strapiData?.cards?.slice(4).map((e, i) => (
                <Col
                  key={i}
                  lg={3}
                  md={6}
                  sm={12}
                  className={`${styles.colPad} d-flex`}
                >
                  <div
                    className={`${styles.card} ${ocr ? styles.ocrCard : ""}`}
                  >
                    <img
                      src={e?.image1?.[0]?.localFile?.publicURL}
                      alt="tick"
                    />
                    <h3
                      dangerouslySetInnerHTML={{
                        __html: e?.title,
                      }}
                      className={`${styles.cardHead} ${
                        ocr ? styles.ocrCardHead : ""
                      }`}
                    ></h3>
                    <p
                      className={`${styles.mainDes} ${
                        ocr ? styles.ocrmainDec : ""
                      }`}
                      dangerouslySetInnerHTML={{
                        __html: e?.subTitle,
                      }}
                    />
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </Container>
      </div>
      <Container className={`${easyfill ? styles.easyfillImg : ""}`}>
        <div
          className={`${styles.laptopContainer} ${
            ocr ? styles.ocrlaptopContainer : ""
          }`}
        >
          <img
            src={
              ocr
                ? ""
                : predicto
                ? "https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1707479483_813bc1bafe.svg"
                : "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1686557173_657988cfef.webp"
            }
            alt={ocr ? "" : "laptop"}
          />
        </div>
      </Container>
    </>
  )
}

export default PerfectEngagement
