// extracted by mini-css-extract-plugin
export var CheerrightBoxBorder = "KeyChalanges-module--CheerrightBoxBorder--ac7be";
export var aiIcon = "KeyChalanges-module--ai-icon--92b0d";
export var arrowAnimation = "KeyChalanges-module--arrowAnimation--cf714";
export var atomicBg = "KeyChalanges-module--atomicBg--82240";
export var btnContainer = "KeyChalanges-module--btnContainer--5ec61";
export var challengeContent = "KeyChalanges-module--challengeContent--88ccd";
export var challengeRow = "KeyChalanges-module--challengeRow--2d1a4";
export var challengesGrid = "KeyChalanges-module--challengesGrid--611af";
export var cheerBg = "KeyChalanges-module--cheerBg--1543d";
export var cheerleftBoxBorder = "KeyChalanges-module--cheerleftBoxBorder--dfbb5";
export var connectingLine = "KeyChalanges-module--connectingLine--97cfe";
export var content = "KeyChalanges-module--content--2b982";
export var graphIcon = "KeyChalanges-module--graph-icon--c1f24";
export var icon = "KeyChalanges-module--icon--cf485";
export var iconWrapper = "KeyChalanges-module--iconWrapper--3d806";
export var keyChallengesContainer = "KeyChalanges-module--keyChallengesContainer--fc61d";
export var leftBox = "KeyChalanges-module--leftBox--4524a";
export var leftBoxBorder = "KeyChalanges-module--leftBoxBorder--18d23";
export var liveBg = "KeyChalanges-module--liveBg--dea5e";
export var newBtn = "KeyChalanges-module--newBtn--4c048";
export var ocrBg = "KeyChalanges-module--ocrBg--a79d5";
export var ocrBgPd = "KeyChalanges-module--ocrBgPd--2b675";
export var rightBox = "KeyChalanges-module--rightBox--c53e5";
export var rightBoxBorder = "KeyChalanges-module--rightBoxBorder--69eab";
export var solutionContent = "KeyChalanges-module--solutionContent--6bdc8";
export var title = "KeyChalanges-module--title--b2b61";
export var titleWrapper = "KeyChalanges-module--titleWrapper--7c2f1";
export var weaponBg = "KeyChalanges-module--weaponBg--f7b4f";
export var webglIcon = "KeyChalanges-module--webgl-icon--af70f";