import React from "react"
import Container from "react-bootstrap/Container"
import * as styles from "./CenterImage.module.scss"
import { Row, Col } from "react-bootstrap"

function Conclusion() {
  return (
    <Container>
      <div className={styles.laptopImg2}>
      
        <img
       src={
   
        "https://invozone-backend.s3.us-east-1.amazonaws.com/group_1686557416_4x_493d4094a5.webp"

   }
         
          alt="laptop"
          decoding="async"
          loading="lazy"
        />
       

        
      
      
      </div>
    </Container>
  )
}

export default Conclusion
