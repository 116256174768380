// extracted by mini-css-extract-plugin
export var bets = "KeyFeatures-module--bets--a1ec8";
export var card = "KeyFeatures-module--card--e8d5f";
export var cardHead = "KeyFeatures-module--cardHead--a5bb5";
export var colPad = "KeyFeatures-module--colPad--3ea63";
export var description = "KeyFeatures-module--description--8bb97";
export var easyfillImg = "KeyFeatures-module--easyfillImg--77770";
export var heading = "KeyFeatures-module--heading--0120e";
export var laptopContainer = "KeyFeatures-module--laptopContainer--c4dec";
export var mainDes = "KeyFeatures-module--mainDes--58f24";
export var ocrBets = "KeyFeatures-module--ocrBets--679fa";
export var ocrCard = "KeyFeatures-module--ocrCard--c3b4d";
export var ocrCardHead = "KeyFeatures-module--ocrCardHead--a422a";
export var ocrlaptopContainer = "KeyFeatures-module--ocrlaptopContainer--32dcb";
export var ocrmainDec = "KeyFeatures-module--ocrmainDec--cee94";
export var weaponHeading = "KeyFeatures-module--weaponHeading--0fd35";