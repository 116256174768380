import React from "react"
import Container from "react-bootstrap/Container"
import * as styles from "./CenterImage.module.scss"
import { Row, Col } from "react-bootstrap"

function Conclusion() {
  return (
    <Container>
      <div className={styles.laptopImg}>
        <Row>
          <Col md={6}>
        <img
       src={
   
        "https://invozone-backend.s3.us-east-1.amazonaws.com/image_18_00e3703b46.webp"

   }
         
          alt="laptop"
          decoding="async"
          loading="lazy"
        />
          <img
          src={
   
            " https://invozone-backend.s3.us-east-1.amazonaws.com/image_4x_5e99666a02.webp"
    
       }
          alt="laptop"
          decoding="async"
          loading="lazy"
          className={styles.img2Container}
        />
          </Col>

          <Col md={6} >
      
             <img
       
         src={
 
          "https://invozone-backend.s3.us-east-1.amazonaws.com/image_17_c8ff90c65f.webp"
  
     }
          alt="laptop"
          decoding="async"
          loading="lazy"
   
        />
        
            <img
             src={
   
              "https://invozone-backend.s3.us-east-1.amazonaws.com/image_19_da50f75989.webp"
      
         }
        
          alt="laptop"
          decoding="async"
          loading="lazy"
          className={styles.img2Container}
        />
        </Col>
     </Row>
      </div>
    </Container>
  )
}

export default Conclusion
