// extracted by mini-css-extract-plugin
export var bannerImage = "Introsection-module--bannerImage--72a21";
export var card = "Introsection-module--card--8915e";
export var cardTitle = "Introsection-module--cardTitle--f715b";
export var description = "Introsection-module--description--92114";
export var descriptionText = "Introsection-module--descriptionText--ec182";
export var golfImg = "Introsection-module--golfImg--53d19";
export var headingContainer = "Introsection-module--headingContainer--029be";
export var headingIntro = "Introsection-module--headingIntro--e2d68";
export var highlight = "Introsection-module--highlight--a6653";
export var liveDescriptionText = "Introsection-module--liveDescriptionText--3f3fa";
export var ocrBannerImage = "Introsection-module--ocrBannerImage--5da55";
export var paragraphAi = "Introsection-module--paragraphAi--5a9de";
export var racinoIntroImg = "Introsection-module--racinoIntroImg--9cb09";
export var sectionContainer = "Introsection-module--sectionContainer--046a9";
export var subTitle = "Introsection-module--subTitle--91e6a";