// extracted by mini-css-extract-plugin
export var arrowAnimation = "Banner1-module--arrowAnimation--dbcdc";
export var backgroundImg = "Banner1-module--backgroundImg--88bd7";
export var bannerCurve = "Banner1-module--bannerCurve--53c1c";
export var bannerHeading = "Banner1-module--bannerHeading--556b2";
export var bannerText = "Banner1-module--bannerText--d07c3";
export var btn = "Banner1-module--btn--63ad2";
export var btne = "Banner1-module--btne--e8e01";
export var curve = "Banner1-module--curve--70b96";
export var dedicated = "Banner1-module--dedicated--21802";
export var dedicatedBanner = "Banner1-module--dedicatedBanner--902c4";
export var dedicatedHeadingStatic = "Banner1-module--dedicatedHeadingStatic--9d70f";
export var description = "Banner1-module--description--55eeb";
export var descriptionText = "Banner1-module--descriptionText--ab70f";
export var headingIntro = "Banner1-module--headingIntro--57272";
export var innerDedicated = "Banner1-module--innerDedicated--dbfe7";
export var kycParagraphAi = "Banner1-module--kycParagraphAi--76953";
export var mainTitle = "Banner1-module--mainTitle--26763";
export var newBtn = "Banner1-module--newBtn--246e4";
export var ocrHeading = "Banner1-module--ocrHeading--536f5";
export var paragraphAi = "Banner1-module--paragraphAi--efca7";
export var racinoParagraphAi = "Banner1-module--racinoParagraphAi--71700";
export var sliderButtons = "Banner1-module--sliderButtons--49c75";
export var subTitle = "Banner1-module--subTitle--64aa5";
export var weaponHeading = "Banner1-module--weaponHeading--2925e";