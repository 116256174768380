import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import * as styles from "./Conclusion.module.scss"

function Conclusion({ strapiData, ocr }) {
  return (
    <Container>
      <div className={`${styles.conclusionBG} ${ocr ? styles.ocrBG : ""}`}>
        <div className={styles.conclusionDiv}>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <h2
                className={`${styles.conclusionHeading} ${
                  ocr ? styles.ocrConclusionHeading : ""
                }`}
                dangerouslySetInnerHTML={{ __html: strapiData?.title }}
              />
              <p
                className={styles.conclusionText}
                dangerouslySetInnerHTML={{ __html: strapiData?.description?.description }}
              />
            </Col>
          </Row>
        </div>
      </div>
    </Container>
  )
}

export default Conclusion
