import React from "react"
import * as styles from "./Introsection.module.scss"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Container } from "react-bootstrap"

const AIStatisticsTrend = ({ strapiData, ocr, kyc, weapon, racino, hatch }) => {
  return (
    <div>
      <div className={styles.sectionContainer}>
        <Container>
          <h2
            className={styles.headingIntro}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <Row>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={5}
              className={styles.headingContainer}
            >
              <p
                className={`${styles.descriptionText} ${
                  kyc ? styles.liveDescriptionText : ""
                }`}
                dangerouslySetInnerHTML={{ __html: strapiData?.subTitle }}
              />
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={7}
              className={styles.headingContainer}
            >
              <p
                className={styles.paragraphAi}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.description?.description,
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <Container
        className={`${styles.bannerImage} ${ocr ? styles.ocrBannerImage : ""}`}
      >
        <Row className="gap-30">
          <Col md={6}>
            <img
              src={
                "https://invozone-backend.s3.us-east-1.amazonaws.com/mask_group_4x_f66d4387ed.webp"
              }
            />
          </Col>
          <Col md={6}>
            <img
              src={
                "https://invozone-backend.s3.us-east-1.amazonaws.com/group_1686557229_4x_8993ed2453.webp"
              }
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default AIStatisticsTrend
